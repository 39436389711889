import Vue from 'vue';
import VueNotifications from 'vue-notifications';
// import radiusPollingService from './radiusPollingService';

export default {
  getSNMPConfigForCPEsByCPEIDs(ctx, cpesIDs) {
    ctx.$store.commit('toggleLoadingSNMPConfigForManyCPEs');
    const query = {
      action: 'R',
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
      q: {
        cpe: { $in: cpesIDs }
      }
    };
    Vue.axios.post(`${window.RESTAPI || ''}/api/monitor/snmp`, query).then(
      (response) => {
        //  console.log(response)
        if (response.data.status === 'success') {
          ctx.snmpConfigListByCpeId = {};
          if (response.data.data.itemslist.length > 0) {
            for (const snmpObj of response.data.data.itemslist) {
              ctx.snmpConfigListByCpeId[snmpObj.cpe] = JSON.parse(JSON.stringify(snmpObj));
            }
            // console.log(response.data.data.itemslist);
          }
          ctx.redrawSNMPStatusesInTable();
        } else if (response.data.status === 'error') {
          ctx.disableSingleEditMode();
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('toggleLoadingSNMPConfigForManyCPEs');
      },
      (err) => {
        ctx.disableSingleEditMode();
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleLoadingSNMPConfigForManyCPEs');
        ctx.error = err;
      }
    );
  },
  getSNMPConfigForCPE(ctx, selectedCpeObject) {
    // console.log(selectedCpeObject)
    const selectedCpeId = selectedCpeObject.id;
    const selectedCpeIP = selectedCpeObject.state.l2tp_state.local_addr;
    const selectedCpeBaseLocation = selectedCpeObject.base_location;
    const selectedCpeHostName = selectedCpeObject.state.l2tp_state.host;
    ctx.$store.commit('toggleLoadingSNMPConfig');
    const query = {
      action: 'R',
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
      q: {
        cpe: selectedCpeId
        // ip: selectedCpeIP
      }
    };
    Vue.axios.post(`${window.RESTAPI || ''}/api/monitor/snmp`, query).then(
      (response) => {
        //  console.log(response)
        if (response.data.status === 'success') {
          if (response.data.data.itemslist) {
            // если не нашли данных по зданной точке, то копируем поля для нового объекта SNMPMonitoringConfig
            // если нашли, то апдейтим существующий объект
            if (response.data.data.itemslist.length === 0) {
              //  console.log('создаем');
              ctx.isNewSNMPMonitoringConfigCreating = true;
              ctx.updatedSNMPMonitoringConfig = JSON.parse(JSON.stringify(ctx.newSNMPMonitoringConfig));
              ctx.updatedSNMPMonitoringConfig.ip = selectedCpeIP;
              ctx.updatedSNMPMonitoringConfig.cpe = selectedCpeId;
              ctx.updatedSNMPMonitoringConfig.host_name = selectedCpeHostName;
              ctx.updatedSNMPMonitoringConfig.base_location = selectedCpeBaseLocation;
            } else if (response.data.data.itemslist.length > 0) {
              ctx.isNewSNMPMonitoringConfigCreating = false;
              const dataFromResponse = response.data.data.itemslist[0];
              // console.log(response.data.data.itemslist)
              ctx.updatedSNMPMonitoringConfig = dataFromResponse;
              // перезаписываем полученные IP и host name и base_location в SNMP объекте свежими, которые сейчас у точки
              ctx.updatedSNMPMonitoringConfig.host_name = selectedCpeHostName;
              ctx.updatedSNMPMonitoringConfig.ip = selectedCpeIP;
              ctx.updatedSNMPMonitoringConfig.base_location = selectedCpeBaseLocation;
            }
          }
        } else if (response.data.status === 'error') {
          ctx.disableSingleEditMode();
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('toggleLoadingSNMPConfig');
      },
      (err) => {
        ctx.disableSingleEditMode();
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.$store.commit('toggleLoadingSNMPConfig');
        ctx.error = err;
      }
    );
  },
  updateSNMPMonitoringConfig(ctx) {
    // ctx.$store.commit('toggleMuteRRMEdit');
    const updateQuery = {
      action: 'U',
      items: {
        [ctx.updatedSNMPMonitoringConfig.id]: ctx.updatedSNMPMonitoringConfig
      }
    };
    Vue.axios.post(`${window.RESTAPI || ''}/api/monitor/snmp`, updateQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.setSNMPConfigForCPE(JSON.parse(JSON.stringify(ctx.updatedSNMPMonitoringConfig)));
          ctx.disableSingleEditMode();

          // ctx.$store.commit('toggleMuteRRMEdit');
          VueNotifications.success({ message: ctx.$t('SNMPMonitoringConfig.SNMPMonitoringConfigNotificationUpdated') });
        } else if (response.data.status === 'error') {
          // ctx.$store.commit('toggleMuteRRMEdit', true);
          VueNotifications.error({ message: response.data.description });
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        // ctx.$store.commit('toggleMuteRRMEdit');
      }
    );
  },
  deleteSNMPMonitoringConfig(ctx) {
    // ctx.$store.commit('toggleMuteRRMEdit');
    const updateQuery = {
      action: 'D',
      items: {
        [ctx.updatedSNMPMonitoringConfig.id]: ctx.updatedSNMPMonitoringConfig
      }
    };
    Vue.axios.post(`${window.RESTAPI || ''}/api/monitor/snmp`, updateQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.deleteSNMPConfigForCPE(JSON.parse(JSON.stringify(ctx.updatedSNMPMonitoringConfig)));
          ctx.disableSingleEditMode();

          // ctx.$store.commit('toggleMuteRRMEdit');
          VueNotifications.success({ message: ctx.$t('SNMPMonitoringConfig.SNMPMonitoringConfigNotificationDeleted') });
        } else if (response.data.status === 'error') {
          // ctx.$store.commit('toggleMuteRRMEdit', true);
          VueNotifications.error({ message: response.data.description });
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        // ctx.$store.commit('toggleMuteRRMEdit');
      }
    );
  },
  createSNMPMonitoringConfig(ctx) {
    // ctx.$store.commit('toggleMuteRRMEdit');
    const updateQuery = {
      action: 'C',
      items: {
        0: ctx.updatedSNMPMonitoringConfig
      }
    };
    Vue.axios.post(`${window.RESTAPI || ''}/api/monitor/snmp`, updateQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          ctx.setSNMPConfigForCPE(JSON.parse(JSON.stringify(ctx.updatedSNMPMonitoringConfig)));
          ctx.disableSingleEditMode();

          // ctx.$store.commit('toggleMuteRRMEdit');
          VueNotifications.success({ message: ctx.$t('SNMPMonitoringConfig.SNMPMonitoringConfigNotificationCreated') });
        } else if (response.data.status === 'error') {
          // ctx.$store.commit('toggleMuteRRMEdit', true);
          VueNotifications.error({ message: response.data.description });
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        // ctx.$store.commit('toggleMuteRRMEdit');
      }
    );
  }
};
